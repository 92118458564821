import React from "react";
import ReactModal from "react-modal";
import Loader from "react-loader-spinner";

const ShowLoader = () => {
  return (
    <ReactModal
      isOpen={true}
      contentLabel="Loading"
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.8)",
          zIndex: 100,
        },
        content: {
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          margin: "auto",
          bottom: "0",
          width: "30px",
          height: "30px",
          borderColor: "#63ceb0",
          borderRadius: "100vw",
          background: "rgba(0,0,0,0)",
          overflow: "hidden",
          WebkitOverflowScrolling: "touch",
          outline: "none",
        },
      }}
    >
      <Loader type="Bars" color="#63ceb0" height={"30px"} width={"30px"} />
    </ReactModal>
  );
};

ReactModal.setAppElement("#root");
export default ShowLoader;
