import React, { Component } from "react";
import { connect } from "react-redux";
import { saveLogin } from "../../redux/actions/loginActions";
import { fetchUser } from "../../redux/actions/userActions";

import { Anchor, Box, DropButton, Image, Text } from "grommet";

import { Menu, Logout, UserSettings } from "grommet-icons";

import { apiconn } from "../../apiconn"; //axios connectors are here
import "react-toastify/dist/ReactToastify.css";

var apiheader = {};

var timercount = 1;

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    if (this.props.login.id_user < 1) this.props.history.replace("/");
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    // if(this.props.login.id_user < 1)
    //   this.doLogout();

    this._fetchUserData();
    this.thetimer = setInterval(() => {
      this._timerFunctions();
    }, 2500);
  }

  componentWillUnmount() {
    clearInterval(this.thetimer);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  accountPanel = () => (
    <Box className="account-options" gap="8px" pad="15px">
      <Anchor
        onClick={() =>
          this.props.history.replace({ pathname: "/edit-profile" })
        }
      >
        <Box direction="row" align="center" gap="5px">
          <UserSettings size="xsmall" />
          <Text size="small">Change Password</Text>
        </Box>
      </Anchor>
      <Anchor onClick={() => this.doLogout()}>
        <Box direction="row" align="center" gap="5px">
          <Logout size="xsmall" color="accent-1" />
          <Text size="small" color="accent-1">
            Logout
          </Text>
        </Box>
      </Anchor>
    </Box>
  );

  doLogout = () => {
    this.props.saveLogin(0, "");
    this.props.history.replace("/");
  };

  _timerFunctions() {
    this._fetchUserData();

    timercount++;
    if (timercount > 60) timercount = 1;
  }

  async _fetchUserData() {
    if (this.props.login.id_user < 1) {
      this.doLogout();
    } else {
      apiconn.getApi
        .get("/user/" + this.props.login.id_user, apiheader)
        .then((response) => {
          if (response.status >= 200 && response.status <= 202) {
            //success
            let resp = response.data;

            // if(resp.id_user == 3){
            //    let access = ["master_data", "catalog", "show_capital_price", "purchase_order_all", "purchase_order_receipt", "marketing", "sales_order_all", "sales_order_shipping", "sales_chart_statistics", "complaint", "complaint_shipping", "finance_all", "finance_crosscheck_ongkir_cash", "finance_bank_operational", "warehouse", "reports_all", "reports_admin", "pro_master_data", "pro_items", "pro_purchase_order_all", "pro_purchase_order_receipt", "pro_batch_all", "pro_batch_parts_input", "pro_batch_parts_output", "pro_assembly", "pro_qc", "pro_packing", "pro_surat_jalan", "pro_warehouse", "pro_finance"];
            //
            //    resp.accessobj = access;
            //  }

            // if(resp.deleted == 1){ //logout if user is deleted
            //     this.doLogout();
            // }
            // else {
            this.props.fetchUser(resp);
            // }
          } else if (response.status === 203) {
            //fail
            //this.props.history.replace("/");
          } else {
            //console.log("Request failed");
          }
        })
        .catch((error) => {
          if (error !== undefined) {
            if (error.response !== undefined) {
              if (error.response.status !== undefined) {
                if (error.response.status === 401) {
                  //unauthorized
                  this.doLogout();
                }
              }
            }
          }

          //this._backgroundFetchAttempt();
        });
    }
  }

  render() {
    return (
      <Box id="top-panel" direction="row" align="center" justify="between">
        {this.state.width > 960 ? (
          <Box direction="row" gap="20px">
            <Menu
              className="clickable"
              color="white"
              onClick={(e) => this.props.toggleMenu()}
            />
            <Box id="logo">
              <Image src="logo2.png" />
            </Box>
          </Box>
        ) : (
          <Menu
            className="clickable"
            color="white"
            onClick={() => this.props.openNav()}
          />
        )}
        {/* <Box id="search-panel" width={size !== 'small' ? '40%' : '70%'}>
          <Form>
            <FormField>
              <TextInput type="text" name="search" icon={<FormSearch />} placeholder="Search..." />
            </FormField>
          </Form>
        </Box> */}
        <DropButton
          dropContent={this.accountPanel()}
          dropProps={{
            className: "rounded-small",
            align: { top: "bottom" },
            margin: { top: "5px" },
            elevation: "small",
          }}
        >
          <Box id="account-panel" direction="row" align="center" gap="xsmall">
            <Image src="avatar.png" />
            {this.state.width > 960 && (
              <Text size="small">{this.props.user.data.username}</Text>
            )}
          </Box>
        </DropButton>
      </Box>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    login: state.loginData,
    user: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveLogin: (id_user, api_token) => {
      dispatch(saveLogin(id_user, api_token));
    },
    fetchUser: (userdata) => {
      dispatch(fetchUser(userdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
