import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//import "./assets/scss/style.scss";
import "./assets/App.css";
import { Preloader } from "./components";
import ScrollToTop from "./helpers/scroll-top";

const Accounts = lazy(() => import("./pages/Accounts"));
const AddAccount = lazy(() => import("./pages/AddAccount"));
const AddBatch = lazy(() => import("./pages/AddBatch"));
const AddCategory = lazy(() => import("./pages/AddCategory"));
const AddColor = lazy(() => import("./pages/AddColor"));
const AddCourier = lazy(() => import("./pages/AddCourier"));
const AddCustomer = lazy(() => import("./pages/AddCustomer"));
const AddDeliveryOrder = lazy(() => import("./pages/AddDeliveryOrder"));
const AddItem = lazy(() => import("./pages/AddItem"));
const AddPrePurchase = lazy(() => import("./pages/AddPrePurchase"));
const AddPurchase = lazy(() => import("./pages/AddPurchase"));
const AddPurchaseRetur = lazy(() => import("./pages/AddPurchaseRetur"));
const AddSales = lazy(() => import("./pages/AddSales"));
const AddSalesman = lazy(() => import("./pages/AddSalesman"));
const AddSalesRetur = lazy(() => import("./pages/AddSalesRetur"));
const AddSize = lazy(() => import("./pages/AddSize"));
const AddSupplier = lazy(() => import("./pages/AddSupplier"));
const AddUnit = lazy(() => import("./pages/AddUnit"));
const AddVendor = lazy(() => import("./pages/AddVendor"));
const AddTransaction = lazy(() => import("./pages/AddTransaction"));
const AddZone = lazy(() => import("./pages/AddZone"));
const Catalog = lazy(() => import("./pages/Catalog"));
const Categories = lazy(() => import("./pages/Categories"));
const Colors = lazy(() => import("./pages/Colors"));
const Commissions = lazy(() => import("./pages/Commissions"));
const Couriers = lazy(() => import("./pages/Couriers"));
const Customers = lazy(() => import("./pages/Customers"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const DeliveryOrder = lazy(() => import("./pages/DeliveryOrder"));
const DeliveryOrderRetur = lazy(() => import("./pages/DeliveryOrderRetur"));
const EditProfile = lazy(() => import("./pages/EditProfile"));
const Finances = lazy(() => import("./pages/Finances"));
const FrontEndAddSales = lazy(() => import("./pages/FrontEndAddSales"));
const FrontEndBudgetRequest = lazy(() =>
  import("./pages/FrontEndBudgetRequest")
);
const FrontEndHome = lazy(() => import("./pages/FrontEndHome"));
const FrontEndMyCommissions = lazy(() =>
  import("./pages/FrontEndMyCommissions")
);
const FrontEndMyCustomers = lazy(() => import("./pages/FrontEndMyCustomers"));
const FrontEndSalesOrder = lazy(() => import("./pages/FrontEndSalesOrder"));
const Inventories = lazy(() => import("./pages/Inventories"));
const Inventory = lazy(() => import("./pages/Inventory"));
const Login = lazy(() => import("./pages/Login"));
const Pos = lazy(() => import("./pages/Pos"));
const PrePurchase = lazy(() => import("./pages/PrePurchase"));
const ProductionBatch = lazy(() => import("./pages/ProductionBatch"));
const ProInventory = lazy(() => import("./pages/ProInventory"));
const PurchaseInvoice = lazy(() => import("./pages/PurchaseInvoice"));
const PurchaseItemList = lazy(() => import("./pages/PurchaseItemList"));
const PurchaseOrder = lazy(() => import("./pages/PurchaseOrder"));
const PurchaseOrderRetur = lazy(() => import("./pages/PurchaseOrderRetur"));
const Reports = lazy(() => import("./pages/Reports"));
const Salesman = lazy(() => import("./pages/Salesman"));
const SalesInvoice = lazy(() => import("./pages/SalesInvoice"));
const SalesmanInvoice = lazy(() => import("./pages/SalesmanInvoice"));
const SalesOrder = lazy(() => import("./pages/SalesOrder"));
const SalesOrderRetur = lazy(() => import("./pages/SalesOrderRetur"));
const SalesReport = lazy(() => import("./pages/SalesReport"));
const SalesReportSupplier = lazy(() => import("./pages/SalesReportSupplier"));
const Settings = lazy(() => import("./pages/Settings"));
const Sizes = lazy(() => import("./pages/Sizes"));
const Statistic = lazy(() => import("./pages/Statistic"));
const Suppliers = lazy(() => import("./pages/Suppliers"));
const Transactions = lazy(() => import("./pages/Transactions"));
const Units = lazy(() => import("./pages/Units"));
const Vendors = lazy(() => import("./pages/Vendors"));
const Zones = lazy(() => import("./pages/Zones"));

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route
              path={process.env.PUBLIC_URL + "/accounts"}
              render={(props) => <Accounts {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-account"}
              exact
              component={AddAccount}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-batch"}
              exact
              component={AddBatch}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-category"}
              exact
              component={AddCategory}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-color"}
              exact
              component={AddColor}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-courier"}
              exact
              component={AddCourier}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-delivery-order"}
              exact
              component={AddDeliveryOrder}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-zone"}
              exact
              component={AddZone}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-customer"}
              exact
              component={AddCustomer}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-item"}
              exact
              component={AddItem}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-pre-purchase"}
              exact
              component={AddPrePurchase}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-purchase"}
              exact
              component={AddPurchase}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-purchase-retur"}
              exact
              component={AddPurchaseRetur}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-sales"}
              exact
              component={AddSales}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-salesman"}
              exact
              component={AddSalesman}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-sales-retur"}
              exact
              component={AddSalesRetur}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-size"}
              exact
              component={AddSize}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-supplier"}
              exact
              component={AddSupplier}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-unit"}
              exact
              component={AddUnit}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-vendor"}
              exact
              component={AddVendor}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-transaction"}
              exact
              component={AddTransaction}
            />
            <Route
              path={process.env.PUBLIC_URL + "/catalog"}
              render={(props) => <Catalog {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/categories"}
              render={(props) => <Categories {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/colors"}
              render={(props) => <Colors {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/commissions"}
              render={(props) => <Commissions {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/couriers"}
              render={(props) => <Couriers {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/customers"}
              render={(props) => <Customers {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/"}
              exact
              component={Login}
            />
            <Route
              path={process.env.PUBLIC_URL + "/dashboard"}
              component={Dashboard}
            />
            <Route
              path={process.env.PUBLIC_URL + "/delivery-order"}
              render={(props) => <DeliveryOrder {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/delivery-order-retur"}
              render={(props) => <DeliveryOrderRetur {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/edit-profile"}
              render={(props) => <EditProfile {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/finances"}
              render={(props) => <Finances {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/home-add-sales"}
              render={(props) => <FrontEndAddSales {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/home-budget-request"}
              render={(props) => <FrontEndBudgetRequest {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/home"}
              render={(props) => <FrontEndHome {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/home-my-commissions"}
              render={(props) => <FrontEndMyCommissions {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/home-my-customers"}
              render={(props) => <FrontEndMyCustomers {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/home-sales-order"}
              render={(props) => <FrontEndSalesOrder {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/inventories"}
              render={props => <Inventories {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/inventory"}
              render={(props) => <Inventory {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/login"}
              render={(props) => <Login {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/pos"}
              render={(props) => <Pos {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/pre-purchase"}
              render={(props) => <PrePurchase {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/production-batch"}
              render={(props) => <ProductionBatch {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/production-inventory"}
              render={(props) => <ProInventory {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/purchase-invoice"}
              render={(props) => <PurchaseInvoice {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/purchase-item-list"}
              render={(props) => <PurchaseItemList {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/purchase-order"}
              render={(props) => <PurchaseOrder {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/purchase-order-retur"}
              render={(props) => <PurchaseOrderRetur {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reports"}
              render={(props) => <Reports {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/salesman"}
              render={(props) => <Salesman {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/sales-invoice"}
              render={(props) => <SalesInvoice {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/salesman-invoice"}
              render={(props) => <SalesmanInvoice {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/sales-order"}
              render={(props) => <SalesOrder {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/sales-order-retur"}
              render={(props) => <SalesOrderRetur {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/sales-report"}
              render={(props) => <SalesReport {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/sales-report-supplier"}
              render={(props) => <SalesReportSupplier {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/settings"}
              render={(props) => <Settings {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/sizes"}
              render={(props) => <Sizes {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/statistic"}
              render={(props) => <Statistic {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/suppliers"}
              render={(props) => <Suppliers {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/transactions"}
              render={(props) => <Transactions {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/units"}
              render={(props) => <Units {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/vendors"}
              render={(props) => <Vendors {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/zones"}
              render={(props) => <Zones {...props} />}
            />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default App;
